import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Link from "next/link";
import axios from "axios";

import useMobile from "../../Hooks/Mobile";
import useScrolledTo from "../../Hooks/ScrolledTo";
import SwipingPanels from "../../SwipingPanels";
import Loader from "../../Loader";

import { links } from "../../../helper/constants";
import { formatLongText } from "../../../helper/formatters";
import { dynamicEvent } from "../../../lib/gtag";

import Backend from "../../../utils/Backend";

import styles from "./styles.module.scss";
import AskNevoBackground from "../../../public/assets/images/asknevo-background.jpg";
import { useIsBoiWebsite } from "../../Context/WebsiteType";

export default function HelpAdvice() {
	const [slide, setSlide] = useState(0);
	const [content, setContent] = useState<any[]>([]);

	const [loadContent, setLoadContent] = useState(false);
	const [failedToLoad, setFailedToLoad] = useState(false);

	const sectionRef = useRef<HTMLDivElement>(null);

	const lineRef = useRef<HTMLDivElement>(null);
	const whyEVRef = useRef<HTMLDivElement>(null);
	const costRef = useRef<HTMLDivElement>(null);
	const speedRef = useRef<HTMLDivElement>(null);

	const tablet = useMobile(900);

	const activateSection = useScrolledTo(sectionRef);

	const isBoiWebsite = useIsBoiWebsite();

	// retrieving cached content
	useEffect(() => {
		const cachedContent = sessionStorage.getItem("helpAdviceContent");

		if (cachedContent) {
			setContent(JSON.parse(cachedContent));
		} else {
			setLoadContent(true);
		}
	}, []);

	// caching content
	useEffect(() => {
		if (content.length === 3) {
			sessionStorage.setItem("helpAdviceContent", JSON.stringify(content));
		}
	}, [content]);

	const getPositionX = (element: HTMLDivElement) => {
		return element.getBoundingClientRect().left;
	};
	const getElementWidth = (element: HTMLDivElement) => {
		return element.getBoundingClientRect().width;
	};

	const moveUndeline = useCallback(() => {
		const refsArray = [whyEVRef, costRef, speedRef];

		const currentRef = refsArray[slide];

		if (currentRef.current && lineRef.current) {
			const positionX = getPositionX(currentRef.current);
			const width = getElementWidth(currentRef.current);

			lineRef.current.style.left = `${positionX}px`;
			lineRef.current.style.width = `${width}px`;
		}
	}, [slide]);

	const fetchContent = (learnType = "ev-education", setter: any) => {
		let backend: any;

		if (learnType === "asknevo") {
			backend = Backend.fetchAskNevoQuestions(1, 0);
		} else if (learnType === "videos") {
			backend = Backend.fetchVideos();
		} else {
			backend = Backend.fetchLearnContent(learnType, 1, 0);
		}

		if (!backend) return;

		backend.promise
			.then(({ data }: any) => {
				if (data?.content?.length) {
					setter(data.content[0]);
				} else throw new Error("No blog data found");
			})
			.catch((e: any) => {
				if (axios.isCancel(e)) return;

				setFailedToLoad(true);
			});

		return backend.cancel;
	};

	// fetching latest blog post
	useEffect(() => {
		if (!activateSection || content[0] || failedToLoad || !loadContent) return;

		const processArticle = (item: any) => {
			content[0] = {
				title: "Learn about EVs",
				contentType: "ev-education",
				header: item.title,
				text: item.content,
				image: item.featured_image?.replaceAll(" ", "%20"),
				link: links.articles
			};
			setContent([...content]);
		};

		const cancel = fetchContent("ev-education", processArticle);

		return cancel;
	}, [activateSection, content, failedToLoad, loadContent]);

	// fetching latest review
	useEffect(() => {
		if (!activateSection || !loadContent || content[1] || failedToLoad || content.length === 0) return;

		const processVideo = (item: any) => {
			content[1] = {
				title: "Latest Review",
				contentType: "Videos",
				header: item.title,
				image: item.thumbnail_image?.replaceAll(" ", "%20"),
				link: links.videos
			};
			setContent([...content]);
		};

		const cancel = fetchContent("videos", processVideo);

		return cancel;
	}, [activateSection, content, failedToLoad, loadContent]);

	// fetching latest question
	useEffect(() => {
		if (!activateSection || !loadContent || content[2] || failedToLoad || content.length < 2) return;

		const processQuestion = (item: any) => {
			content[2] = {
				title: "Get the right answer",
				contentType: "#AskNevo",
				header: item.form_data.title,
				text: item.form_data.message,
				image: AskNevoBackground.src,
				link: links.asknevo
			};
			setContent([...content]);
		};
		const cancel = fetchContent("asknevo", processQuestion);

		return cancel;
	}, [activateSection, content, loadContent, failedToLoad]);

	const contentReady = useMemo(() => {
		return content.length >= 3;
	}, [content]);

	useEffect(() => {
		if (!contentReady) return;

		if (tablet !== undefined && !tablet) {
			moveUndeline();

			window.addEventListener("resize", moveUndeline);
			return () => window.removeEventListener("resize", moveUndeline);
		}
		// re-setting default width for underline for tablet
		else if (lineRef?.current) {
			lineRef.current.style.width = "100%";
		}
	}, [moveUndeline, tablet, contentReady]);

	const handleNextSlide = useCallback(() => {
		if (slide < content.length - 1) {
			setSlide(slide + 1);
		} else setSlide(0);
	}, [slide, content.length]);

	// 15 seconds slide animation
	useEffect(() => {
		if (activateSection && !failedToLoad) {
			const timeout = setTimeout(handleNextSlide, 15000);
			return () => clearTimeout(timeout);
		}
	}, [activateSection, failedToLoad, handleNextSlide]);

	const onSlideClick = (slideNumber: number) => {
		if (slide !== slideNumber) setSlide(slideNumber);
	};

	const trackBenefit = (title: string) => {
		dynamicEvent("Home EV Benefits", `Clicked on ${title}`);
	};

	const renderSlide = (s: number) => {
		if (!contentReady) return;

		return (
			<div key={s} className={`${isBoiWebsite ? styles.slideBoi : styles.slide}`}>
				<h2 className={`${isBoiWebsite ? styles.h2Boi : styles.h2}`}>{content[s].header}</h2>

				{content[s].text && <p className={styles.coolParagraph} dangerouslySetInnerHTML={{ __html: formatLongText(content[s].text, 251) }} />}

				<Link href={content[s].link} passHref>
					<button className={`${styles.learnButton} ${isBoiWebsite ? 'dark-blue-button-boi' : 'blue-button'}`} onClick={() => trackBenefit(content[s].title)}>
						Learn more
					</button>
				</Link>
			</div>
		);
	};

	if (failedToLoad)
		return (
			<div className={`${isBoiWebsite ? styles.holderBoi : styles.holder}`}>
				<h2 className={`${isBoiWebsite ? styles.errorMessageBoi : styles.errorMessage} big-header`}>Failed to load content</h2>
			</div>
		);

	if (!contentReady)
		return (
			<div className={`${isBoiWebsite ? styles.holderBoi : styles.holder}`} ref={sectionRef}>
				<Loader />
			</div>
		);

	return (
		<div className={`${isBoiWebsite ? styles.presentationBoi : styles.presentation}`} ref={sectionRef}>
			{!tablet && (
				<div className={`${isBoiWebsite ? styles.contentControlsBoi : styles.contentControls}`}>
					<div
						className={`${isBoiWebsite ? styles.controlHolderBoi : styles.controlHolder}`}
						onClick={() => {
							onSlideClick(0);
						}}
					>
						<div className={isBoiWebsite ? styles.controlBoi : styles.control} ref={whyEVRef}>
							<p className={`${isBoiWebsite ? styles.contentTypeBoi : styles.contentType} big-text`}>EV Education</p>

							<h2 className={`${isBoiWebsite ? styles.h2Boi : styles.h2}`}>Learn about EVs</h2>
							<div className={`${isBoiWebsite ? styles.underlineBoi : styles.underline}` + " " + (!tablet ? isBoiWebsite ? styles.activeBoi : styles.active : slide === 0 ? isBoiWebsite ? styles.activeBoi : styles.active : "")} ref={lineRef} />
						</div>
					</div>

					<div className={`${isBoiWebsite ? styles.separetorBoi : styles.separetor}`} />

					<div
						className={`${isBoiWebsite ? styles.controlHolderBoi : styles.controlHolder}`}
						onClick={() => {
							onSlideClick(1);
						}}
					>
						<div className={isBoiWebsite ? styles.controlBoi : styles.control} ref={costRef}>
							<p className={`${isBoiWebsite ? styles.contentTypeBoi : styles.contentType} big-text`}>Videos</p>

							<h2 className={`${isBoiWebsite ? styles.h2Boi : styles.h2}`}>Latest Review</h2>

							<div className={`${isBoiWebsite ? styles.underlineBoi : styles.underline}` + " " + (tablet && slide === 1 ? isBoiWebsite ? styles.activeBoi : styles.active : "")} />
						</div>
					</div>

					<div className={`${isBoiWebsite ? styles.separetorBoi : styles.separetor} + " " + ${isBoiWebsite ? styles.lastSeparatorBoi : styles.lastSeparator}}`} />

					<div
						className={`${isBoiWebsite ? styles.controlHolderBoi : styles.controlHolder}`}
						onClick={() => {
							onSlideClick(2);
						}}
					>
						<div className={`${isBoiWebsite ? styles.controlBoi : styles.control}`} ref={speedRef}>
							<p className={`${isBoiWebsite ? styles.contentTypeBoi : styles.contentType} big-text`}>#AskNevo</p>

							<h2 className={`${isBoiWebsite ? styles.h2Boi : styles.h2}`}>Get the right answer</h2>

							<div className={`${isBoiWebsite ? styles.underlineBoi : styles.underline}` + " " + (tablet && slide === 2 ? isBoiWebsite ? styles.activeBoi : styles.active : "")} />
						</div>
					</div>
				</div>
			)}

			{!tablet ? (
				<div className={`${isBoiWebsite ? styles.contentBoi : styles.content}`}>
					<div key={slide} className={`${isBoiWebsite ? styles.imageBoi : styles.image}`} style={{ backgroundImage: `url(${content[slide].image})` }}>
						&nbsp;
					</div>

					<div className={`${isBoiWebsite ? styles.contentTextBoi : styles.contentText}`}>{renderSlide(slide)}</div>
				</div>
			) : (
				<SwipingPanels panel={slide} setPanel={setSlide} controlsClassName={styles.controls}>
					{content.map((item: any, index: number) => (
						<div key={index} className={`${isBoiWebsite ? styles.contentBoi : styles.content}`}>
							<p className={`${isBoiWebsite ? styles.contentTypeBoi : styles.contentType} big-text`}>{item.contentType}</p>

							<h2 className={`${isBoiWebsite ? styles.mobileTitleBoi : styles.mobileTitle}`}>{item.title}</h2>

							<div key={slide} className={`${isBoiWebsite ? styles.imageBoi : styles.image}`} style={{ backgroundImage: `url(${content[index].image})` }}>
								&nbsp;
							</div>

							<div className={`${isBoiWebsite ? styles.contentTextBoi : styles.contentText}`}>{renderSlide(index)}</div>
						</div>
					))}
				</SwipingPanels>
			)}
		</div>
	);
}
